import React from 'react';
import Layout from '../../../layout/Layout/Layout';
import Header from '../Header/Header';
import Navigation from '../Navigation/Navigation';
import Section from '../../../layout/Section/Section';
import * as styles from './page.module.scss';
import { classNames } from '../../../../utils';
import { useTranslation } from 'react-i18next';


export default function Page( { heading, localizedHeading, desc, children, pageContext, isDocument } ) {
  const { i18n } = useTranslation();
  const Wrapper = isDocument ? 'article' : 'section';

  return (
    <Layout>
      {/*<SEO title={ localizedHeading || heading } description={ desc } isArticle />*/}

      <Wrapper>
        <Header heading={ heading } localizedHeading={ localizedHeading } desc={ desc } />

        <Section
          as="div"
          className={ classNames( 'content', styles.pageSection ) }
          contentBodyClassName={ classNames(
            styles.pageBody,
            isDocument && styles.pageBodyDocument,
            i18n.language !== 'en' && styles.pageBodyMulti
          ) }
          hasSidebar
          hasContentStyles
          hasDecoration={ ! isDocument }
        >
          { children }

          { pageContext && (
            <div className={ styles.pageFooter }>
              <Navigation
                prev={ pageContext.prev }
                next={ pageContext.next }
              />
            </div>
          ) }
        </Section>
      </Wrapper>
    </Layout>
  );
}