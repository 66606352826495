import React from 'react';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import * as styles from './header.module.scss';
import { classNames } from '../../../../utils';
import VERSIONS from '../../../../constants/versions';
import { useLocation } from '@reach/router';
import { Icon } from '../../../components/Icon/Icon';
import { useTranslation } from 'react-i18next';


export default function Header( { heading, localizedHeading } ) {
  const { t } = useTranslation();
  const outdated = isOutdated();

  return (
    <header className={ classNames( styles.header, outdated ? 'has-gradient-bg-gray' : 'has-gradient-bg' ) }>
      <div className={ styles.headerContent }>
        <Breadcrumbs className={ styles.headerBreadcrumbs }/>

        { localizedHeading &&
        <>
          <p className={ styles.headerHeading }>
            { heading }
          </p>
          <h1 className={ styles.headerLocalizedHeading }>
            { localizedHeading }
          </h1>
        </>
        }

        { ! localizedHeading &&
        <h1 className={ styles.headerHeading }>
          { heading }
        </h1>
        }

        { outdated &&
        <div className={ styles.headerNotification }>
          <Icon type="caution" className={ styles.headerNotificationIcon }/>
          <span> { t( 'outdated' ) }</span>
        </div>
        }
      </div>

      <div
        aria-hidden="true"
        className={ classNames( styles.headerDecoration, outdated && styles.headerDecorationOutdated ) }
      >
        { heading }
      </div>
    </header>
  );
}

function isOutdated() {
  const location = useLocation();

  return VERSIONS.slice( 1 ).some( entry => {
    return location.pathname.includes( `/${ entry.version }/` );
  } );
}