import React from 'react';
import Caution from '../../../assets/images/svg/caution.svg';
import Chat from '../../../assets/images/svg/chat.svg';
import Arrow from '../../../assets/images/svg/arrow.svg';
import Check from '../../../assets/images/svg/check.svg';
import Angle from '../../../assets/images/svg/angle.svg';
import { classNames } from '../../../utils';
import * as styles from './icon.module.scss';


const ICONS = {
  caution: Caution,
  info   : Chat,
  arrow  : Arrow,
  check  : Check,
  angle  : Angle,
};

export function Icon( { type, className } ) {
  const icon = ICONS[ type ];

  if ( icon ) {
    const IconComponent = props => React.createElement( icon, props );
    return (
      <IconComponent className={ classNames( styles.icon, className ) } />
    );
  }

  return null;
}