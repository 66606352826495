import React from 'react';
import { graphql } from 'gatsby';
import { DocumentsPage } from '../../components/scenes/DocumentsPage/DocumentsPage';
import { SEO } from '../../components/seo/SEO/SEO';


export default function Archives( { data } ) {
  return (
    <DocumentsPage data={ data } heading={ "Archives" }/>
  );
}

export function Head() {
  return (
    <SEO
      title={ 'Archives' }
      description={ 'Archived documents of version 3.' }
    />
  );
}

export const queryV3Documents = graphql`
  query queryV3Documents {
    allMdx(
      filter: {fields: {version: {eq: "v3"}}}
      sort: {order: ASC, fields: frontmatter___order}
    ) {
      edges {
        node {
          fields {
            category
            language
            slug
            version
          }
          id
          frontmatter {
            title
            localizedTitle
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;