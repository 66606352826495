import React from 'react';
import * as styles from './document-list.module.scss';
import Arrow from '../../../assets/images/svg/arrow.svg';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../../utils';
import { Link } from 'gatsby';


export default function DocumentList( { docs } ) {
  const { i18n } = useTranslation();

  return (
    <ul className={ styles.documentList }>
      { docs.map( ( { node } ) => (
        <li key={ node.id } className={ styles.documentListItem }>
          <Link
            to={ node.fields.slug }
            className={ classNames(
              styles.documentListLink,
              i18n.language !== 'en' && styles.documentListLinkMulti
            ) }
          >
            <span>{ node.frontmatter.localizedTitle || node.frontmatter.title }</span>
            {/*<span>*/}
            {/*  Last Update: { node.frontmatter.date }*/}
            {/*</span>*/}
            <Arrow className={ styles.documentListArrow } />
          </Link>
        </li>
      ) ) }
    </ul>
  );
}