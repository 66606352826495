import Page from '../Page/Page/Page';
import CATEGORIES from '../../../constants/categories';
import DocumentList from '../DocumentList/DocumentList';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SEO } from '../../seo/SEO/SEO';


export function DocumentsPage( { data, heading } ) {
  const { i18n, t } = useTranslation( 'common' );

  return (
    <Page heading={ heading }>
      { CATEGORIES.map( ( { category }, index ) => {
        const edges = data.allMdx.edges.filter( edge => {
          const { fields } = edge.node;
          return fields.category === category && fields.language === i18n.language;
        } );

        return (
          <section key={ category }>
            <h2 style={ { marginTop: index === 0 ? null : '4rem', marginBottom: '2rem' } }>{ t( category ) }</h2>
            <DocumentList docs={ edges } />
          </section>
        );
      } ) }
    </Page>
  );
}
