// extracted by mini-css-extract-plugin
export var header = "header-module--header--1154q";
export var headerContent = "header-module--header-content--IhnaP";
export var headerBreadcrumbs = "header-module--header-breadcrumbs--YuIT1";
export var headerHeading = "header-module--header-heading--5qIJj";
export var headerLocalizedHeading = "header-module--header-localizedHeading--xoesM";
export var headerIntro = "header-module--header-intro--cyaaE";
export var headerDecoration = "header-module--header-decoration--DYXXg";
export var headerDecorationOutdated = "header-module--header-decoration-outdated--ggDdC";
export var headerNotification = "header-module--header-notification--bQlcm";
export var headerNotificationIcon = "header-module--header-notification-icon--7OjwC";