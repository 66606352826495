import React from 'react';
import Angle from '../../../assets/images/svg/angle.svg';
import * as styles from './breadcrumbs.module.scss';
import { createBreadcrumbs } from '../../../utils';
import { useLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';


export default function Breadcrumbs( { className } ) {
  const crumbs = createBreadcrumbs( useLocation(), useTranslation().t );

  return (
    <nav aria-label="breadcrumb" className={ className }>
      <ul>
        { crumbs.slice( 0, -1 ).map( crumb => (
          <li key={ crumb.path } className={ styles.breadcrumbsItem }>
            <Link to={ crumb.path } className={ styles.breadcrumbsLink }>{ crumb.label }</Link>
            <Angle className={ styles.breadcrumbsSeparator } role="presentation"/>
          </li>
        ) ) }
      </ul>
    </nav>
  );
}