import React from 'react';
import * as styles from './navigation.module.scss';
import { classNames } from '../../../../utils';
import Arrow from '../../../../assets/images/svg/arrow.svg';
import { Link } from 'gatsby';


export default function Navigation( { className, prev, next } ) {
  if ( prev || next ) {
    return (
      <nav className={ classNames( styles.navigation, className ) }>
        <div className={ styles.navigationPrev }>
          { prev && (
            <Link to={ prev.fields.slug } className={ styles.navigationLink }>
              <Arrow className={ classNames( styles.navigationArrow, styles.navigationArrowPrev ) } />
              <span>{ prev.frontmatter.localizedTitle || prev.frontmatter.title }</span>
            </Link>
          ) }
        </div>

        <div className={ styles.navigationNext }>
          { next && (
            <Link to={ next.fields.slug } className={ styles.navigationLink }>
              <span>{ next.frontmatter.localizedTitle || next.frontmatter.title }</span>
              <Arrow className={ classNames( styles.navigationArrow, styles.navigationArrowNext ) } />
            </Link>
          ) }
        </div>
      </nav>
    );
  }

  return null;
}